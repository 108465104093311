<template>
  <div class="dashboard">
    <div class="static-data">
      <!-- 静态数据显示 -->
      <span style="font-size: 4.0vh;color: #87CEEB">荣昌玉米制种基地气象站</span>
      <div class="data-point" v-for="data in staticData" :key="data.label">
        <span class="label">{{ data.label }}</span>
        <span class="value">{{ data.value }}</span>
      </div>
    </div>
    <div class="line-chart">
      <!-- ECharts折线图 -->
      <div class="chart" id="chart"></div>
    </div>
  </div>
</template>

<script>

import * as echarts from 'echarts';
export default {
  name: "SkyInfo",
  data() {
    return {
      username: "dianjiang@test.com",
      password: "123456",
      xData:[],
      Temperature:[],//温度
      Humidity:[],//湿度
      Rain:[],//降雨量
      WindSpeed:[],//风速
      TemperatureMax:[],//最大温度
      staticData: [
        { label: '温度（℃）', value: '' },
        { label: '露点温度（℃）', value: '' },
        { label: '湿度（%）', value: '' },
        { label: '气压（Pa）', value: '' },
        { label: '海平面气压（Pa）', value: '' },
        { label: '当⽇累计降⾬量（mm）', value: '' },
        { label: '1⼩时降⾬强度（mm）', value: '' },
        { label: '风速（m/s）', value: '' },
        { label: '风级（级）', value: '' }
      ],

    }
  },
  mounted() {
    this.initEcharts();
  },

  created() {
    this.init()
  },


  methods: {
    //初始化信息，获得数据
    async init() {
      //获取token
      const token = localStorage.getItem("tokenWeather");
      //token不存在就去登录获取最新的token
      if (!token) {
        let obj = { username: this.username, password: this.password}
        console.info("123")
        await this.$axios.post("https://obsapi.kantiantech.com/auth/login/", obj).then(res => {
          localStorage.setItem("tokenWeather",res.data.auth_token)
        })
      }
      //获取设备静态信息
      await this.$axios.get("https://obsapi.kantiantech.com/devc/current_data/E0E2E6598A75/").then(res => {
        this.staticData[0].value = res.data.Sky.Temperature //温度
        this.staticData[1].value = res.data.Sky.Dewpoint //露点温度
        this.staticData[2].value = res.data.Sky.Humidity //湿度
        this.staticData[3].value = res.data.Sky.Pressure //气压
        this.staticData[4].value = res.data.Sky.SeaLevelPressure //海平面气压
        this.staticData[5].value = res.data.Storm.RainDailyNew_mm //当⽇累计降⾬量
        this.staticData[6].value = res.data.Storm.RainRate_mm //1⼩时降⾬强度
        this.staticData[7].value = res.data.Storm.WindSpeed_mps //风速
        this.staticData[8].value = res.data.Storm.WindSpeed_level //风级
      })



    },
    async initEcharts() {

      //获取token
      const token = localStorage.getItem("tokenWeather");
      //token不存在就去登录获取最新的token
      if (!token) {
        let obj = { username: this.username, password: this.password}
        console.info("123")
        await this.$axios.post("https://obsapi.kantiantech.com/auth/login/", obj).then(res => {
          localStorage.setItem("tokenWeather",res.data.auth_token)
        })
      }
      var chartDom = document.getElementById('chart');
      var myChart = echarts.init(chartDom);
      var option;
      //获取折线图实时数据

      // 获取今天日期的最早时间（00:00）
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // 格式化日期为所需的格式（例如：2024-04-26-00-00
      const formattedToday = today.toISOString().slice(0, 10) + "-00-05";
      const formattedToday2 = today.toISOString().slice(0, 10) + "-23-59";


      // 构建URL
      const url = `https://obsapi.kantiantech.com/devc/archive_data/minutely/E0E2E6598A75/${formattedToday}/${formattedToday2}/?action=`;

      // 发起请求
      await this.$axios.get(url).then(res => {
        this.xData = res.data.map(item => item.DateTime);
        this.Temperature = res.data.map(item => item.Temperature);
        this.Humidity = res.data.map(item => item.Humidity);
        this.Rain = res.data.map(item => item.Rain);
        this.WindSpeed = res.data.map(item => item.WindSpeed);
        this.TemperatureMax = res.data.map(item => item.TemperatureMax);
      });

      option = {
        title: {
          text: '气象站数据图',
          textStyle: {
            color: 'white' // 设置标题字体颜色为白色
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['平均温度','最大温度','平均湿度','降雨量','平均风速']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xData,
          axisLabel: {
            color: 'white', // 设置X轴标题字体颜色为白色
            formatter: function (value) {
              // 这里可以根据自己的需求进行时间格式化
              return value.split(' ')[1].slice(0, 5); // 只显示时分部分，例如：14:28
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white' // 设置Y轴标题字体颜色为白色
          }
        },
        series: [
          {
            name: '平均温度',
            type: 'line',
            stack: 'Total',
            data: this.Temperature
          },{
            name: '最大温度',
            type: 'line',
            stack: 'Total',
            data: this.TemperatureMax
          },
          {
            name: '平均湿度',
            type: 'line',
            stack: 'Total',
            data: this.Humidity
          },
          {
            name: '降雨量',
            type: 'line',
            stack: 'Total',
            data: this.Rain
          },
          {
            name: '平均风速',
            type: 'line',
            stack: 'Total',
            data: this.WindSpeed
          },

        ]
      };

      myChart.setOption(option);
    }

  },

}
</script>

<style scoped>
.dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  color: white;
  background-image: url('../assets/WeatherImage.png'); /* 图片链接 */
  background-size: cover;
  background-repeat: no-repeat;
  gap: 20px;
}


.static-data {
  flex: 1;
  padding: 10px;
  border-right: 2px solid #333; /* 分隔线 */
}
.data-point {
  margin-bottom: 10px;
}
.label {
  display: block; /* 标签占据一行 */
  font-size: 2.5vh;
  color: #aaa; /* 浅灰色字体 */
}
.value {
  font-size: 2.7vh;
  font-weight: bold; /* 加粗字体 */
}


.line-chart {
  flex: 2;
  padding: 10px;
}

/* ECharts折线图的容器样式 */
.chart {
  margin-top: 2em;
  height: 90vh; /* 图表高度 */
}

</style>
