// 请求摄像头accesstoken
import axios from "axios";

export function requestVideoToken() {
    return axios({
        url: 'https://open.ys7.com/api/lapp/token/get',
        method: 'post',
        params: {
            appKey: 'd0eca43ee08f4efba3e1909381900578',
            appSecret: 'bd58930a995fdcb74405b7734b1d332b'
        }
    });
}

