import { createRouter,createWebHashHistory } from 'vue-router'

import MapPage from "@/pages/MapPage.vue";
import video1 from "@/pages/video1.vue";
import video2 from "@/pages/video2.vue";
import video3 from "@/pages/video3.vue";
import video4 from "@/pages/video4.vue";
import video5 from "@/pages/video5.vue";
import video6 from "@/pages/video6.vue";
import video7 from "@/pages/video7.vue";
import video8 from "@/pages/video8.vue";
import video9 from "@/pages/video9.vue";
import video10 from "@/pages/video10.vue";
import video11 from "@/pages/video11.vue";
import video12 from "@/pages/video12.vue";
import video13 from "@/pages/video13.vue";
import video14 from "@/pages/video14.vue";
import video15 from "@/pages/video15.vue";
import video16 from "@/pages/video16.vue";
import video17 from "@/pages/video17.vue";
import video18 from "@/pages/video18.vue";
import video19 from "@/pages/video19.vue";
import video20 from "@/pages/video20.vue";

import mapViewer10 from "@/pages/MapViewer10.vue";
import SkyInfo from "@/pages/SkyInfo.vue";
import mapViewer1 from "@/pages/MapViewer1.vue";
import mapViewer2 from "@/pages/MapViewer2.vue";
import mapViewer3 from "@/pages/MapViewer3.vue";
import mapViewer4 from "@/pages/MapViewer4.vue";


const routes = [
  {
    path: '/',
    name: 'mapPage',
    component:MapPage
  },{
    path: '/SkyInfo',
    name: 'SkyInfo',
    component:SkyInfo
  },{
    path: '/video1',
    name: 'video1',
    component:video1
  },{
    path: '/video2',
    name: 'video2',
    component:video2
  },{
    path: '/video3',
    name: 'video3',
    component:video3
  },{
    path: '/video4',
    name: 'video4',
    component:video4
  },{
    path: '/video5',
    name: 'video5',
    component:video5
  },{
    path: '/video6',
    name: 'video6',
    component:video6
  },{
    path: '/video7',
    name: 'video7',
    component:video7
  },{
    path: '/video8',
    name: 'video8',
    component:video8
  },{
    path: '/video9',
    name: 'video9',
    component:video9
  },{
    path: '/video10',
    name: 'video10',
    component:video10
  },{
    path: '/video11',
    name: 'video11',
    component:video11
  },{
    path: '/video12',
    name: 'video12',
    component:video12
  },{
    path: '/video13',
    name: 'video13',
    component:video13
  },{
    path: '/video14',
    name: 'video14',
    component:video14
  },{
    path: '/video15',
    name: 'video15',
    component:video15
  },{
    path: '/video16',
    name: 'video16',
    component:video16
  },{
    path: '/video17',
    name: 'video17',
    component:video17
  },{
    path: '/video18',
    name: 'video18',
    component:video18
  },{
    path: '/video19',
    name: 'video19',
    component:video19
  },{
    path: '/video20',
    name: 'video20',
    component:video20
  },{
    path: '/mapViewer10',
    name: 'mapViewer10',
    component:mapViewer10
  },{
    path: '/mapViewer1',
    name: 'mapViewer1',
    component:mapViewer1
  },{
    path: '/mapViewer2',
    name: 'mapViewer2',
    component:mapViewer2
  },{
    path: '/mapViewer3',
    name: 'mapViewer3',
    component:mapViewer3
  },{
    path: '/mapViewer4',
    name: 'mapViewer4',
    component:mapViewer4
  }
]

const router = createRouter({
  history:createWebHashHistory(),
  routes,
})

export default router;

