<template>
  <div id="video-container" class="video-player">
    <!-- 播放器将在这个容器中初始化 -->
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js';
import {requestVideoToken} from "@/api/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "video14",
  async mounted() {
    //获取token
    const token = localStorage.getItem("videoAccessToken");
    //获取token时间
    const videoAccessTokenTime = localStorage.getItem("videoAccessTokenTime");
    if (!token || Date.now() - videoAccessTokenTime > 6 * 24 * 60 * 60 * 1000) {
      await requestVideoToken().then(res => {
        localStorage.setItem("videoAccessToken", res.data.data.accessToken)
        localStorage.setItem("videoAccessTokenTime", Date.now()); // 存储token获取时间戳)
      })
    }
    //获取token
    const tokenLatest = localStorage.getItem("videoAccessToken");
    // 此时token为有效，直接初始化EZUIKitPlayer
    this.player = new EZUIKit.EZUIKitPlayer({
      id: 'video-container', // 实际存在的 DOM 元素的 ID
      accessToken: tokenLatest,
      url: 'ezopen://open.ys7.com/AE0101421/1.hd.live',
      template: 'pcLive',
    });
  }
}
</script>

<style scoped>
.video-player {
  width: 100%;
  height: 100vh;
  aspect-ratio: 16 / 9;
}
</style>
