<template>
  <div class="map-container">
    <div id="mapDiv" class="map"></div>
    <div class="content">
      <div class="bg">
        <span class="h1">日期{{ date }} </span>
        <img class="set" src="../assets/set.png" @click="toBack()" alt="">
        <img class="menu" src="../assets/menu.png" @click="toOil()" alt="">
        <div class="title">园区地图</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      date:'',
    }
  },
  mounted() {
    //初始化地图
    this.onLoad();
    setInterval(() => {
      this.getData(Date.now());
      this.date = this.getData(Date.now());
    }, 1000);
  },
  methods: {
    //初始化地图信息
    async onLoad() {
      var imageURL = "https://t0.tianditu.gov.cn/img_w/wmts?" +
          "SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
          "&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ef01f1b92b29e876c76aca2e27489fa7";
      //创建自定义图层对象
      var T = window.T;
      var zoom = 15;
      var lay = new T.TileLayer(imageURL, { minZoom: 10, maxZoom: 18 });
      var config = { layers: [lay] };
      //初始化地图对象
      var map = new T.Map("mapDiv", config);
      //设置显示地图的中心点和级别
      map.centerAndZoom(new T.LngLat(105.43881923832368, 29.42075986722857), zoom);
      // 定义多边形数组
      var polygons = [];



      var polygon10 = new T.Polygon([
        new T.LngLat(105.43893061158731, 29.42559293690953),
        new T.LngLat(105.44019041661015, 29.426862724701785),
        new T.LngLat(105.44147334396075, 29.42784836386524),
        new T.LngLat(105.44193895419991, 29.429241531091822),
        new T.LngLat(105.44174829788426, 29.42992427717115),
        new T.LngLat(105.44253029680071, 29.430718198489934),
        new T.LngLat(105.44337502924063, 29.431676776340154),
        new T.LngLat(105.44296459690585, 29.432387677910754),
        new T.LngLat(105.44409132367242, 29.43386827981765),
        new T.LngLat(105.44462541768598, 29.433923894204415),
        new T.LngLat(105.44500285930124, 29.43378746788574),
        new T.LngLat(105.44556789517975, 29.434062337114504),
        new T.LngLat(105.44616485224952, 29.434145454049606),
        new T.LngLat(105.44739025497697, 29.434339151564455),
        new T.LngLat(105.44849071805288, 29.43401193504871),
        new T.LngLat(105.44921421973814, 29.433300715664927),
        new T.LngLat(105.45031461600523, 29.43256290391044),
        new T.LngLat(105.45012698409226, 29.43119298658255),
        new T.LngLat(105.4504728428516, 29.430645996980623),
        new T.LngLat(105.44950077262601, 29.428345920113067),
        new T.LngLat(105.44667891253535, 29.425360943884918),
        new T.LngLat(105.44442747538979, 29.42219181933632),
        new T.LngLat(105.43893061158731, 29.42559293690953),

      ], {color:"#0000ff", weight:2, opacity:0.5});  //创建多边形
      polygon10.addEventListener("click", () => this.moveToNewPage(10));
      polygon10.addEventListener("mouseover", function () {
        var infoWindow = new T.InfoWindow("先锋区域"); // 创建信息窗口
        polygon10.openInfoWindow(infoWindow); // 在鼠标位置显示信息窗口
      });
      // 监听覆盖物鼠标移出事件
      polygon10.addEventListener("mouseout", function () {
        polygon10.closeInfoWindow(); // 关闭信息窗口
      });
      polygons.push(polygon10);

      //[[29.430243,105.439696],[29.428317,105.440167],[29.427439,105.439761],[29.425988,105.438742],[29.425043,105.437608],[29.425043,105.437362],[29.425516,105.436893],[29.425881,105.436326],[29.426675,105.435932],[29.427104,105.435143],[29.430346,105.435464],[29.430797,105.435488],[29.430947,105.439333],[29.430243,105.439696]]
      //沙洲坝4号
      var polygon4 = new T.Polygon([
        new T.LngLat(105.4318286150604,29.433211409440005),
        new T.LngLat(105.43210094354012, 29.433209938835642),
        new T.LngLat(105.43229052177024, 29.433375648680755),
        new T.LngLat(105.43340108369078, 29.433367217794874),
        new T.LngLat(105.43457164073374, 29.433367514457935),
        new T.LngLat(105.43524195320175, 29.433341520564774),
        new T.LngLat(105.43574204927575, 29.433175996043175),
        new T.LngLat(105.43632174943716, 29.43286254202847),
        new T.LngLat(105.43653876171938, 29.430721448959748),
        new T.LngLat(105.43619898644555, 29.430825852697303),
        new T.LngLat(105.43587881913037, 29.430442777922526),
        new T.LngLat(105.43594816255737, 29.429564809632108),
        new T.LngLat(105.43468808874538, 29.428119663400597),
        new T.LngLat(105.43338954863717, 29.42824971082476),
        new T.LngLat(105.43163790916054, 29.429389347606133),
        new T.LngLat(105.4318286150604, 29.433211409440005),

      ], {color:"#0000ff", weight:2, opacity:0.5});  //创建多边形
      polygon4.addEventListener("click", () => this.moveToNewPage(4));
      polygon4.addEventListener("mouseover", function () {
        var infoWindow = new T.InfoWindow("沙坝子4号地"); // 创建信息窗口
        polygon4.openInfoWindow(infoWindow); // 在鼠标位置显示信息窗口
      });
      // 监听覆盖物鼠标移出事件
      polygon4.addEventListener("mouseout", function () {
        polygon4.closeInfoWindow(); // 关闭信息窗口
      });
      polygons.push(polygon4);
      //沙洲坝3号
      var polygon3 = new T.Polygon([
        new T.LngLat(105.4272089650323,29.425304473793098),
        new T.LngLat(105.428421833028,29.428210683206878),
        new T.LngLat(105.43067188153972,29.42907793232027),
        new T.LngLat(105.43293039603206,29.428030131262144),
        new T.LngLat(105.43435549048529,29.426197859324812),
        new T.LngLat(105.43511781637145,29.423473498713847),
        new T.LngLat(105.43277655095045,29.422473261076078),
        new T.LngLat(105.42854294086362,29.424032417621255),
        new T.LngLat(105.42708376353652,29.424682899152604),
        new T.LngLat(105.4272089650323,29.425304473793098),
      ], {color:"#0000ff", weight:2, opacity:0.5});  //创建多边形
      polygon3.addEventListener("click", () => this.moveToNewPage(3));
      polygon3.addEventListener("mouseover", function () {
        var infoWindow = new T.InfoWindow("沙坝子3号地"); // 创建信息窗口
        polygon3.openInfoWindow(infoWindow); // 在鼠标位置显示信息窗口
      });
      // 监听覆盖物鼠标移出事件
      polygon3.addEventListener("mouseout", function () {
        polygon3.closeInfoWindow(); // 关闭信息窗口
      });
      polygons.push(polygon3);
      //沙洲坝2号
      var polygon2 = new T.Polygon([
        new T.LngLat(105.42451232293934,29.418013546219257),
        new T.LngLat(105.42650706371803,29.42020976177594),
        new T.LngLat(105.4276739216847,29.42066368235814),
        new T.LngLat(105.42958325482654,29.420677995677053),
        new T.LngLat(105.43286087848861,29.41811699864171),
        new T.LngLat(105.42832649244998,29.41437241844015),
        new T.LngLat(105.42628829787056,29.41422149822328),
        new T.LngLat(105.42414386033309,29.417713311406064),
        new T.LngLat(105.42451232293934,29.418013546219257),
      ], {color:"#0000ff", weight:2, opacity:0.5});  //创建多边形
      polygon2.addEventListener("click", () => this.moveToNewPage(2));
      polygon2.addEventListener("mouseover", function () {
        var infoWindow = new T.InfoWindow("沙坝子2号地"); // 创建信息窗口
        polygon2.openInfoWindow(infoWindow); // 在鼠标位置显示信息窗口
      });
      // 监听覆盖物鼠标移出事件
      polygon2.addEventListener("mouseout", function () {
        polygon2.closeInfoWindow(); // 关闭信息窗口
      });
      polygons.push(polygon2);
      //沙洲坝1号
      var polygon1 = new T.Polygon([
        new T.LngLat(105.433463, 29.416329),
        new T.LngLat(105.433268, 29.415252),
        new T.LngLat(105.433106, 29.414006),
        new T.LngLat(105.434081, 29.412702),
        new T.LngLat(105.434862, 29.411172),
        new T.LngLat(105.435317, 29.409642),
        new T.LngLat(105.434764, 29.407971),
        new T.LngLat(105.433398, 29.405959),
        new T.LngLat(105.431577, 29.405902),
        new T.LngLat(105.429821, 29.406327),
        new T.LngLat(105.42917, 29.407121),
        new T.LngLat(105.428845, 29.408509),
        new T.LngLat(105.428292, 29.410322),
        new T.LngLat(105.430048, 29.411229),
        new T.LngLat(105.430569, 29.412986),
        new T.LngLat(105.429658, 29.413751),
        new T.LngLat(105.430861, 29.41596),
        new T.LngLat(105.431805, 29.417037),
        new T.LngLat(105.432748, 29.417604)
      ], {color:"#0000ff", weight:2, opacity:0.5});  //创建多边形
      polygon1.addEventListener("click", () => this.moveToNewPage(1));
      polygon1.addEventListener("mouseover", function () {
        var infoWindow = new T.InfoWindow("沙坝子1号地"); // 创建信息窗口
        polygon1.openInfoWindow(infoWindow); // 在鼠标位置显示信息窗口
      });
      // 监听覆盖物鼠标移出事件
      polygon1.addEventListener("mouseout", function () {
        polygon1.closeInfoWindow(); // 关闭信息窗口
      });
      polygons.push(polygon1);

      // 将所有多边形添加到地图上
      polygons.forEach(function(polygon) {
        map.addOverLay(polygon);
      });
    },
    moveToNewPage(id){
      if (id === 10){//先锋地块
        window.open(window.location.href + 'MapViewer10', '_blank');
      }else if (id === 4){//4号地
        window.open(window.location.href + 'MapViewer4', '_blank');
      }else if (id === 3){//3号地
        window.open(window.location.href + 'MapViewer3', '_blank');
      }else if (id === 2){//2号地
        window.open("http://online.wish3d.com/Wish3DEarth/LSGlobe/scene.html?guid=bcec7bff4134d812162ca9a978eccb5b&shareId=e91bdeeb947e407c8f5464dc2823b912&errorCode=0");
      }else if (id === 1){//1号地
        window.open(window.location.href + 'MapViewer1', '_blank');
      }
    },
    getData(n) {
      n = new Date(n)
      return n.toLocaleDateString().replace(/\//g, "/") + " " + n.toTimeString().substr(0, 8)
    },
    //导览页
    toOil() {
      window.location.href = "http://rongchang.cauerp.com/RongChang/Admin/index.html#/new/index"//跳转到导览页
    },
    //后台
    toBack() {
      window.location.href = "http://rongchang.cauerp.com/RongChang/Admin/index.html#/Back/RealTime"//跳转到后台管理页
    }

  },
}
</script>

<style>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.map {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* 地图作为底层元素 */
}

.content {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; /* 确保内容显示在地图上方 */
}

.bg {
  height: 12vh; /* 调整为与内容容器高度一致 */
  width: 100%;
  background: url("../assets/dabiaoti.png") no-repeat;
  background-size: 100% 100%;
}
.h1 {
  position: absolute;
  font-weight: bold;
  left: 0.38rem; /* 调整为margin-left */
  margin-top: 0.6rem;
}
.menu {
  margin-left: 1%;
  margin-top: 0.6rem;
}
.set {
  margin-top: 0.6rem;
  margin-left: 90%;
}


.title {
  font-size: 2rem;
  color: #fff;
  background: linear-gradient(to bottom, #2bd19d, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: SourceHanSansSC-Bold;
}
</style>
