import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';


axios.defaults.baseURL = '';

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("tokenWeather");
        if (token) {
            config.headers['Authorization'] = "Token "+token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
);

const app = createApp(App);
app.config.globalProperties.$axios = axios; // 将 $axios 添加到应用程序实例的全局属性中
app.config.productionTip = false;

app.use(router);
app.mount('#app');
