<template>
  <div class="map-container">
    <!-- 使用 iframe 替换原本的地图部分 -->
    <iframe id="mapFrame" class="map"  width="100%" height="100%"></iframe>
  </div>
</template>

<script>

export default {
  data() {
    return {
      date:'',
    }
  },
  mounted() {
    // 在 mounted 钩子中设置 iframe 的 src 属性为目标 URL
    this.setIframeSrc();

  },
  methods: {
    setIframeSrc() {
      // 获取 iframe 元素
      const iframe = document.getElementById('mapFrame');
      // 设置 iframe 的 src 属性为目标 URL
      iframe.src = 'http://online.wish3d.com/Wish3DEarth/LSGlobe/scene.html?guid=bcec7bff4134d812162ca9a978eccb5b&shareId=e91bdeeb947e407c8f5464dc2823b912&errorCode=0'; // 替换为你的目标 URL
    },
  },
}
</script>

<style>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* 地图作为底层元素 */
}

</style>
